import { Card, Button, Row, Col, Spinner } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
// import axios from 'axios';
import { axiosAT } from '../../interceptors/axiosInter'
import RTable from './HistoryTable';
import Container from 'react-bootstrap/Container';

const HistoryTab = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the server when the component mounts
    const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/activities`;
    axiosAT.get(url)
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setLoading(false);
      });
  }, []);


  const columns = React.useMemo(
    () => [
      {
        Header: "Názov Súboru",
        accessor: "filename"
      },
      {
        Header: "Poznámka",
        accessor: "note"
      },
      {
        Header: "Vytvorené",
        accessor: "created_at"
      },
      {
        Header: "Status",
        accessor: "status"
      },
    ],
    []
  );

  return (
    <>
      <Container>
        <h2 className='mb-4'>Všetky tvoje záznamy sú zobrazené na tomto mieste</h2>

        <div>
          {loading ? (
            <Spinner animation="border" role="status">
            </Spinner>
          ) : (
            <RTable columns={columns} data={data} />
          )}
        </div>
      </Container>
    </>
  );
};

export default HistoryTab;
