import React from "react";
import { useNavigate } from "react-router-dom";

import BTable from "react-bootstrap/Table";

import { useTable, useSortBy, usePagination } from "react-table";
import { FaCheckCircle, FaTimesCircle, FaExclamationCircle, FaArrowRight } from "react-icons/fa";
import { RiProgress3Fill } from "react-icons/ri";
import { convertToLocalTime } from '../utils/timeUtils';


export default function RTable({ columns, data }) {
  const navigate = useNavigate();

  // Add a new column for the arrow icon
  const newColumns = React.useMemo(() => [
    ...columns,
    {
      Header: 'Detail',
      id: 'action',
      Cell: ({ row }) => (
        <FaArrowRight
          style={{ cursor: 'pointer' }}
          onClick={() => handleRowClick(row.original.PartitionKey)}
        />
      )
    }
  ], [columns]);


  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns: newColumns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'created_at', // default sorting
            desc: true
          }
        ]
      }
    },
    useSortBy,
    usePagination
  );


  // Custom cell renderer for status column
  const renderStatusCell = (value) => {
    switch (value) {
      case "completed":
        return (<> <FaCheckCircle style={{ color: "green" }} /> Dokončené</>);
      case "pending_ai":
        return (<> <RiProgress3Fill style={{ color: "blue" }} /> Spracovávam</>);
      case "failed":
        return (<> <FaTimesCircle style={{ color: "red" }} /> Chyba</>);
      case "warning":
        return (<> <FaExclamationCircle style={{ color: "orange" }} /> Varovanie</>);
      default:
        return value;
    }
  };


  const renderCreatedAtCell = (value) => {
    return convertToLocalTime(value)
  };

  // Click handler for row
  const handleRowClick = (rowId) => {
    navigate(`/aktivita/${rowId}`);
  };



  // Render the UI for your table
  return (
    <div>
      <div className="table-wrapper">
        <div className="table-scroll">
          <BTable striped bordered hover size="sm" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.column.id === "status"
                            ? renderStatusCell(cell.value)
                            : cell.column.id === "created_at"
                              ? renderCreatedAtCell(cell.value)
                              : cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </BTable>
        </div>
      </div>
      <div className="pagination" style={{ marginTop: "10px" }}>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button
          style={{ marginRight: "10px" }}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>{" "}
        <span>
          Strana{" "}
          <strong>
            {pageIndex + 1} z {pageOptions.length}
          </strong>{" "}
           
          {/* | Vyber stranu:{" "}
          <input
            type="number"
            min="1"
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          /> */}
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Riadkov {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
