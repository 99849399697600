

export const convertToLocalTime = (utcString) => {
    const utcDate = new Date(utcString + 'Z'); // Append 'Z' to indicate UTC time
    return utcDate.toLocaleString();
  };


// 
export const isOlderThanXMinutes = (createdAt, x_minutes) => {
    const createdAtDate = new Date(createdAt + 'Z')
    const now = new Date();
    const diffInMinutes = (now - createdAtDate) / (1000 * 60);
    return diffInMinutes > x_minutes;
  };