import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import ActivitiesTab from './components/tabs/ActivitiesTab';
import HistoryTab from './components/tabs/HistoryTab';
import CreateActivityPage from './components/pages/CreateActivityPage';
import ActivityDetailPage from './components/pages/ActivityDetailPage';
import SubscriptionTab from './components/tabs/SubscriptionTab';
import LoginPage from './components/auth_pages/Login';
import AppNavbar from './components/AppNavbar';
import LandingPage from './components/pages/LandingPage';
import DataPrivacyPage from './components/pages/DataPrivacyPage';


import './styles/App.css';


function App() {
  return (
    <BrowserRouter>
      <AppNavbar />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/aktivity" element={<ActivitiesTab />} />
          <Route path="/historia" element={<HistoryTab />} />
          <Route path="/spotreba" element={<SubscriptionTab />} />
          <Route path="/vytvor-aktivitu" element={<CreateActivityPage />} />
          <Route path="/aktivita/:id" element={<ActivityDetailPage />} />
          <Route path="/data-privacy" element={<DataPrivacyPage />} />
          </Routes>
    </BrowserRouter>
  );
}

export default App;
