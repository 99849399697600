import axios from "axios";

const commonErrorInterceptor = async (error, instance) => {
    if (error.response.status === 401) {
        console.log('401 returned by original requet')
        localStorage.removeItem('access_token');
        window.location.href = '/login'
    }
    return error;
};


// local axios instance with refresh and cache
const axiosAT = axios.create({
});

axiosAT.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

axiosAT.interceptors.response.use(resp => resp, 
    async error => commonErrorInterceptor(error, axios),);


export { axiosAT }