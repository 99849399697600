import { Link, useNavigate, useLocation  } from 'react-router-dom'
import React, { useState, useEffect  } from 'react';
import axios from "axios";
// import '../../assets/css/animate.css';
// import '../../assets/css/lineicons.css';
// import '../../assets/css/main.css';
import { FaCheckCircle, FaFileUpload } from "react-icons/fa";
import { TfiPencilAlt } from "react-icons/tfi";

import myLogo from '../../assets/img/logo_trans.png';

const LandingPage = () => {

    const navigate = useNavigate()
    const location = useLocation();

    const [contactFormData, setContactFormData] = useState({
        email: '',
        message: ''
    });
    const [isContactLoading, setIsContactLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');


    useEffect(() => {
        if (location.hash === '#contact') {
          document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
        }
      }, [location]);


    const handleContactFormChange = (e) => {
        const { name, value } = e.target;
        setContactFormData({
            ...contactFormData,
            [name]: value
        });
    };

    const handleContactFormSubmit = async (e) => {
        e.preventDefault();
        setIsContactLoading(true);
        setSuccessMessage('');
        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/api/v1/send-email', contactFormData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                setContactFormData({ email: '', message: '' });
                setSuccessMessage('Správa bola úspešne odoslaná!');
            } else {
                console.log('Error occurred while sending teh email.')
                setSuccessMessage('Správa nebola odoslaná, skúste to neskôr :(');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setSuccessMessage('Správa nebola odoslaná, skúste to neskôr :(');

        } finally {
            setIsContactLoading(false);
        }
    };

    return (
        <>
            {/* Header */}
            <header className="header fixed-top">
                <div className="navbar-area sticky">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg">
                                    <a className="navbar-brand">
                                        <img src={myLogo} alt="Logo" style={{ maxHeight: '50px', width: 'auto' }} /> Radšej To Nahraj
                                    </a>

                                    <div
                                        className="collapse navbar-collapse sub-menu-bar"
                                        id="navbarSupportedContent"
                                    >
                                        <ul id="nav" className="navbar-nav ms-auto">
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#home">Úvod</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#akonato">Ako na to</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#about">Ako to funguje</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#why">Využitie</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#pricing">Cena</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#contact">Kontakt</a>
                                            </li>
                                        </ul>
                                    </div>

                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>


            {/* Hero Section */}
            <section id="home" className="hero-section">
                <div className="container">
                    <div className="row align-items-center position-relative">
                        <div className="col-lg-6">
                            <div className="hero-content">
                                <h1 className="wow fadeInUp" data-wow-delay=".4s">
                                    Automatická sumarizácia audio súboru
                                </h1>
                                <p className="wow fadeInUp" data-wow-delay=".6s">
                                    Vytváraš manuálne text podľa poznámok? <b style={{ fontSize: '1.2em' }}>Radšej to nahraj</b> a my to prepíšeme za teba!
                                </p>
                                <a
                                    className="main-btn border-btn btn-hover wow fadeInUp"
                                    data-wow-delay=".6s"
                                    onClick={() => navigate('/login')}
                                >
                                    Začať
                                </a>
                                <a href="#akonato" className="scroll-bottom">
                                    <i className="lni lni-arrow-down"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="hero-img wow fadeInUp" data-wow-delay=".5s">
                                {/* <img src="assets/img/hero/hero-img.png" alt="" /> */}
                                <img className="img-fluid" style={{ maxHeight: '500px', width: 'auto' }} src="assets/img/abstract3_trans.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Ako na to Section */}
            <section id="akonato" className="feature-section pt-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
                            <div className="section-title text-center mb-60">
                                <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                                    Ako na to?
                                </h2>
                                <p className="wow fadeInUp" data-wow-delay=".4s">
                                    Po zaregistrovaní a prihlásení vykonaj tieto kroky.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <div className="single-feature">
                                <div className="icon">
                                    <i className="lni lni-music"></i>
                                </div>
                                <div className="content">
                                    <h3>Nahraj aktivitu</h3>
                                    <p>
                                        Môže to byť napr. komentár moderátora, poznámky autora alebo nahrávka schôdze.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <div className="single-feature">
                                <div className="icon">
                                    <FaFileUpload />
                                </div>
                                <div className="content">
                                    <h3>Vlož audio súbor do našej aplikácie</h3>
                                    <p>
                                        Zvoľ požadovaný typ výstupu ako napr. zápisnica z domovej schôdze.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <div className="single-feature">
                                <div className="icon">
                                    <FaCheckCircle />
                                </div>
                                <div className="content">
                                    <h3>Skopíruj textový výstup</h3>
                                    <p>
                                        Naša aplikácia automaticky zosumarizuje audio súbor a vytvorí text v požadovanom formáte.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* About Section */}
            <section id="about" className="about-section pt-150">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6">
                            <div className="about-img">
                                {/* <img src="assets/img/about/about-1.png" alt="" className="w-100" /> */}
                                <img className="img-fluid" src="assets/img/abstract2_trans.png" alt="" />

                                <img
                                    src="assets/img/about/about-left-shape.svg"
                                    alt=""
                                    className="shape shape-1"
                                />
                                <img
                                    src="assets/img/about/left-dots.svg"
                                    alt=""
                                    className="shape shape-2"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="about-content">
                                <div className="section-title mb-30">
                                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                                        Ako to funguje?
                                    </h2>
                                    <p className="wow fadeInUp" data-wow-delay=".4s">
                                        Spracovanie textu podľa nahrávky je na základe AI technológie. Nejedná sa o doslovný prepis z nahrávky na text. Ide o sumarizáciu a formuláciu výstupu podľa preddefinovaných inštrukcií.
                                    </p>
                                    <br />
                                    <p className="wow fadeInUp" data-wow-delay=".4s">
                                        Poskytujeme aj ďalšie užitočné funkcie napr. prehľad tvojej histórie nahrávok alebo možnosť pridávať vlastné poznámky k nahrávkam.
                                    </p>
                                </div>
                                <a
                                    className="main-btn btn-hover border-btn wow fadeInUp"
                                    data-wow-delay=".6s"
                                    onClick={() => navigate('/login')}
                                >
                                    Začať
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Why Choose Us Section */}
            <section id="why" className="feature-extended-section pt-100">
                <div className="feature-extended-wrapper">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
                                <div className="section-title text-center mb-60">
                                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                                        Príklady použitia
                                    </h2>
                                    <p className="wow fadeInUp" data-wow-delay=".4s">
                                        Existuje viacero možných spôsobov použitia našej "Radšej to nahraj" aplikácie.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-feature-extended">
                                    <div className="icon">
                                        <TfiPencilAlt />
                                    </div>
                                    <div className="content">
                                        <h3>Zápisnica z domovej schôdze</h3>
                                        <p>
                                            Automatická tvorba zápisnice z domovej schôdze podľa poznámok nahraných moderátorom alebo správcom, prípadne z nahrávky celej schôdze.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-feature-extended">
                                    <div className="icon">
                                        <i className="lni lni-display"></i>
                                    </div>
                                    <div className="content">
                                        <h3>Sumarizácia konferencie</h3>
                                        <p>
                                            Sumarizácia celého obsahu konferencie, rokovania alebo stretnutia v požadovanom formáte.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-feature-extended">
                                    <div className="icon">
                                        <i className="lni lni-grid-alt"></i>
                                    </div>
                                    <div className="content">
                                        <h3>Vlastná definícia</h3>
                                        <p>
                                            Nahrávka môže byť spracovaná aj podľa vlastných inštrukcií napr. aký obsah zachytiť, ako ho spracovať a aký formát výstupu dodržať.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Pricing Section */}
            <section id="pricing" className="pricing-section pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
                            <div className="section-title text-center mb-35">
                                <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                                    Flexibilná tarifa
                                </h2>
                                <p className="wow fadeInUp" data-wow-delay=".4s">
                                    Zvoľ si tarifu podľa potreby. Predplatený kredit sa spotrebúva podľa dĺžky nahrávok.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <div className="single-pricing">
                                <div className="pricing-header">
                                    <h1 className="price">€0</h1>
                                    <h3 className="package-name">Účet Zdarma</h3>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> Testovacia verzia
                                        </li>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> Dĺžka jednej nahrávky max 1.5 hodiny
                                        </li>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> Kredit v hodnote 1
                                        </li>
                                        <li><i className="lni lni-checkmark active"></i> Technická podpora</li>
                                        {/* <li><i className="lni lni-close"></i> Vhodné na prácu s citlivými údajmi</li> */}
                                    </ul>
                                </div>
                                <div className="pricing-btn">
                                    <a onClick={() => navigate('/login')} className="main-btn btn-hover border-btn">Začať</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <div className="single-pricing">
                                <div className="pricing-header">
                                    <h1 className="price">€10</h1>
                                    <h3 className="package-name">Mesačné Predplatné</h3>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> PRO verzia
                                        </li>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> Dĺžka jednej nahrávky max 9 hodín
                                        </li>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> Kredit v hodnote 10
                                        </li>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> Technická podpora
                                        </li>
                                        {/* <li>
                                            <i className="lni lni-checkmark active"></i> Vhodné na prácu s citlivými údajmi
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="pricing-btn">
                                    <a onClick={() => navigate('/login')} className="main-btn btn-hover">Začať</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <div className="single-pricing">
                                <div className="pricing-header">
                                    <h1 className="price">€100</h1>
                                    <h3 className="package-name">Ročné Predplatné</h3>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> PRO verzia
                                        </li>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> Dĺžka jednej nahrávky max 9 hodín
                                        </li>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> Kredit v hodnote 120
                                        </li>
                                        <li>
                                            <i className="lni lni-checkmark active"></i> Technická podpora
                                        </li>
                                        {/* <li>
                                            <i className="lni lni-checkmark active"></i> Vhodné na prácu s citlivými údajmi
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="pricing-btn">
                                    <a onClick={() => navigate('/login')} className="main-btn btn-hover border-btn">Začať</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* Contact Section */}
            <section id="contact" className="subscribe-section pt-120">
                <div className="container">
                    <div className="subscribe-wrapper img-bg">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-7">
                                <div className="section-title mb-15">
                                    <h2 className="text-white mb-25">Kontaktuj nás</h2>
                                    <p className="text-white pr-5">
                                        Kontaktuj nás, ak máš akúkoľvek otázku, alebo ak chceš otestovať našu aplikáciu zdarma.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5">
                                <form onSubmit={handleContactFormSubmit} className="contact-form">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Tvoj Email"
                                        className="form-control mb-3"
                                        value={contactFormData.email}
                                        onChange={handleContactFormChange}
                                        required
                                    />
                                    <textarea
                                        name="message"
                                        id="message"
                                        placeholder="Správa"
                                        className="form-control mb-3"
                                        rows="4"
                                        value={contactFormData.message}
                                        onChange={handleContactFormChange}
                                        required
                                    ></textarea>
                                    <div className="d-flex align-items-center">
                                        <button
                                            type="submit"
                                            className="main-btn border-btn btn-hover wow fadeInUp"
                                            data-wow-delay=".6s"
                                            style={{ borderColor: '#fff', color: '#fff' }}
                                            disabled={isContactLoading}
                                        >
                                            {isContactLoading ? 'Čakajte...' : 'Poslať'}
                                        </button>
                                        {successMessage && (
                                            <div className="ms-3 d-flex align-items-center" style={{ borderColor: '#fff', color: '#fff' }}>
                                                <FaCheckCircle size="30" className="me-2"/> {successMessage}
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* Footer */}
            {/* <footer class="footer">
                <div class="container">
                    <div class="widget-wrapper">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="footer-widget">
                                    <div class="logo mb-30">
                                        <a href="index.html">
                                            <img src="assets/img/logo/logo.svg" alt="" />
                                        </a>
                                    </div>
                                    <p class="desc mb-30 text-white">
                                        Radsej to nahraj a my to prepiseme za teba!
                                    </p>
                                    <ul class="socials">
                                        <li>
                                            <a href="jvascript:void(0)">
                                                <i class="lni lni-facebook-filled"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="jvascript:void(0)">
                                                <i class="lni lni-twitter-filled"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="jvascript:void(0)">
                                                <i class="lni lni-instagram-filled"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="jvascript:void(0)">
                                                <i class="lni lni-linkedin-original"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-2 col-lg-2 col-md-6">
                                <div class="footer-widget">
                                    <h3>About Us</h3>
                                    <ul class="links">
                                        <li><a href="javascript:void(0)">Home</a></li>
                                        <li><a href="javascript:void(0)">Feature</a></li>
                                        <li><a href="javascript:void(0)">About</a></li>
                                        <li><a href="javascript:void(0)">Testimonials</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="footer-widget">
                                    <h3>Features</h3>
                                    <ul class="links">
                                        <li><a href="javascript:void(0)">Privacy policy</a></li>
                                        <li><a href="javascript:void(0)">Privacy policy</a></li>
                                        <li><a href="javascript:void(0)">Terms of service</a></li>
                                        <li><a href="javascript:void(0)">Refund policy</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-6">
                                <div class="footer-widget">
                                    <h3>Other Products</h3>
                                    <ul class="links">
                                        <li><a href="jvascript:void(0)">Accounting Software</a></li>
                                        <li><a href="jvascript:void(0)">Billing Software</a></li>
                                        <li><a href="jvascript:void(0)">Booking System</a></li>
                                        <li><a href="jvascript:void(0)">Tracking System</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}

            <footer className="footer" style={{ color: 'white' }}>
                <div className="container">
                    <div className="row text-center d-flex align-items-center">
                        {/* Logo Column */}
                        <div className="col-12 col-sm-6">
                            <div className="footer-widget">
                                <div className="logo mb-2">
                                    <a>
                                        <img className="img-fluid" src={myLogo} alt="Logo" style={{ maxHeight: '75px', width: 'auto' }} /> Radšej To Nahraj
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Facebook Icon Column */}
                        {/* <div className="col">
                            <div className="widget-wrapper">
                                <div className="footer-widget">
                                    <ul className="socials">
                                        <li>
                                            <a href="javascript:void(0)">
                                                <i className="lni lni-facebook-filled"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}

                        {/* Privacy Policy Column */}
                        <div className="col-12 col-sm-6">
                            <div className="footer-widget my-4">
                                <a href="" onClick={() => navigate('/data-privacy')} style={{ color: 'white' }}>Ochrana Údajov</a>
                            </div>
                        </div>

                        {/* Contact Column */}
                        {/* <div className="col">
                            <div className="footer-widget">
                                <ul className="links">
                                    <li><a href="javascript:void(0)" style={{ color: 'white' }}>Contact</a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>

            {/* Scroll Top Button */}
            <a href="#" className="scroll-top btn-hover">
                <i className="lni lni-chevron-up"></i>
            </a>
        </>
    );
};

export default LandingPage;