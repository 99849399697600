import { Link, useNavigate } from 'react-router-dom';
import { Card, Button, Row, Col, Spinner, Table } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import { axiosAT } from '../../interceptors/axiosInter';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { convertToLocalTime } from '../utils/timeUtils';
import Container from 'react-bootstrap/Container';

const SubscriptionTab = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch data from the server when the component mounts
        const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/subscription`;
        axiosAT.get(url)
            .then(response => {
                // Sort data by expiry_date in descending order
                const sortedData = response.data.sort((a, b) => new Date(b.expiry_date) - new Date(a.expiry_date));
                setData(sortedData);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setLoading(false);
            });
    }, []);

    const TableSubscriptions = () => {
        return (
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Dátum vytvorenia</th>
                        <th>Dátum expirácie</th>
                        <th>Kredit</th>
                        <th>Spotrebované</th>
                        <th>Stav</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => {
                        const progressValue = Math.min((item.user_price / item.value * 100).toFixed(2), 100);
                        return (
                            <tr key={item.PartitionKey}>
                                <td>{convertToLocalTime(item.creation_date)}</td>
                                <td>{convertToLocalTime(item.expiry_date)}</td>
                                <td>{item.value}</td>
                                <td>
                                    {item.status === 'expired' ? 'Expirvané' : `${progressValue} %`}
                                </td>
                                <td style={{ width: '30%' }}>
                                    {item.status === 'expired' ? 'Expirvané' : (
                                        <ProgressBar
                                            now={progressValue}
                                            label={progressValue > 30 ? `${progressValue} %` : ''}
                                        />
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    return (
        <>
            <Container>
                <h2 className='mb-4'>Spotreba</h2>
                <div>
                    {loading ? (
                        <Spinner animation="border" role="status">
                        </Spinner>
                    ) : (
                        <>
                            <div>Prehľad všetkých aktivovaných balíkov spotreby.</div>
                            <TableSubscriptions />
                        </>
                    )}
                </div>
            </Container>
        </>
    );
};

export default SubscriptionTab;
