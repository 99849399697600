import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// import axios from 'axios';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle, FaExclamationCircle, FaQuestionCircle, FaHourglassEnd } from 'react-icons/fa';
import { RiProgress3Fill } from "react-icons/ri";
import ReactMarkdown from 'react-markdown';

import { axiosAT } from '../../interceptors/axiosInter'
import { convertToLocalTime, isOlderThanXMinutes } from '../utils/timeUtils';

const ActivityDetailPage = () => {

  const { id } = useParams();
  const [activity, setActivity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [intervalId, setIntervalId] = useState(null);


  const fetchData = () => {
    const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/activity/${id}`;
    axiosAT.get(url)
      .then(response => {
        setActivity(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the activity data!', error);
        setLoading(false);
      });
  };


  useEffect(() => {
    fetchData();

    let id_int;
    if (activity?.status === "pending_ai") {
      id_int = setInterval(fetchData, 5000);
      setIntervalId(id_int);
    }

    return () => {
      if (id_int) {
        clearInterval(id_int);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [id, activity?.status]);



  if (loading) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status">
        </Spinner>
      </Container>
    );
  }

  const getStatusIcon = (activity) => {
    switch (activity.status) {
      case "completed":
        return (<> <FaCheckCircle style={{ color: "green" }} /> Dokončené</>);
      case "pending_ai":
        if (isOlderThanXMinutes(activity.created_at, 30)) {
          return (<> <FaHourglassEnd style={{ color: "blue" }} /> Expiruje</>);
        }
        return (<> <RiProgress3Fill style={{ color: "blue" }} /> Spracovávam</>);
      case "failed":
        return (<> <FaTimesCircle style={{ color: "red" }} /> Chyba</>);
      case "warning":
        var error_text = ''
        if (activity.error_user) {
          error_text = ' - ' + activity.error_user
        }
        return (<> <FaExclamationCircle style={{ color: "orange" }} /> Varovanie {error_text}</>);
      default:
        return activity.status;
    }
  };

  const activityTypeMapping = {
    at_general: 'Vlastná definícia',
    at_schodza: 'Zápisnica zo schôdze'
  };

  return (
    <Container className="mt-5">
      <Card>
        <Card.Header as="h2" className="text-center">Detail Aktivity</Card.Header>
        {activity && <Card.Body>
          <Row className="mb-3">
            <Col md={3}><strong>Typ aktivity:</strong></Col>
            <Col md={9}>{activityTypeMapping[activity.activity_type] || activity.activity_type}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}><strong>Poznámka:</strong></Col>
            <Col md={9}>{activity.note}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}><strong>Inštrukcie:</strong></Col>
            <Col md={9}>{activity.instructions}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}><strong>Nahraný súbor:</strong></Col>
            <Col md={9}>{activity.filename}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}><strong>Vytvorené dňa:</strong></Col>
            <Col md={9}>{convertToLocalTime(activity.created_at)}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}><strong>Spracované dňa:</strong></Col>
            <Col md={9}>{activity.processed_at && convertToLocalTime(activity.processed_at)}</Col>
          </Row>

          <Row className="mb-3">
            <Col md={3}><strong>Status:</strong></Col>
            <Col md={9}>{getStatusIcon(activity)}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}><strong>Výstup:</strong></Col>
            <Col md={9}>
              <ReactMarkdown>{activity.output}</ReactMarkdown>
            </Col>

          </Row>
        </Card.Body>}
      </Card>
    </Container>
  );
};

export default ActivityDetailPage;
