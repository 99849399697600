import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import icon from '../assets/img/logo_trans.png'

const AppNavbar = (props) => {

  const location = useLocation();
  const navigate = useNavigate();


  // do not show app navbar for landing page
  if (location.pathname === '/' || location.pathname === '/data-privacy') {
    return null;

  }


  const UserNavbar = () => {

    const [activeTab, setActiveTab] = useState(location.pathname);

    useEffect(() => {
      setActiveTab(location.pathname);
    }, [location]);

    const handleSelect = (eventKey) => {
      setActiveTab(eventKey);
    };

    const handleBrandClick = () => {
      if (location.pathname === '/login') {
        navigate('/');
      }
      else {
        navigate('/aktivity');
      }
    }

    return (
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand onClick={handleBrandClick} style={{ cursor: 'pointer' }} className="d-flex align-items-center">
            <img
              src={icon}
              alt="Logo"
              className="d-inline-block align-top"
              style={{ maxHeight: '50px', width: 'auto' }}
            />
            {/* <span className="ms-2">Sumarizator</span> */}

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" activeKey={activeTab} onSelect={handleSelect}>
              <Nav.Link as={Link} to="/aktivity" eventKey="/aktivity" className={activeTab === '/aktivity' ? 'active-tab' : ''}>Nová aktivita</Nav.Link>
              <Nav.Link as={Link} to="/historia" eventKey="/historia" className={activeTab === '/historia' ? 'active-tab' : ''}>História</Nav.Link>
              <Nav.Link as={Link} to="/spotreba" eventKey="/spotreba" className={activeTab === '/spotreba' ? 'active-tab' : ''}>Spotreba</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }



  return (
    <>
      <UserNavbar />
    </>
  );
};

export default AppNavbar;